<template>
  <div class="container-fluid p-4">
    <div class="d-flex pb-2">
      <!-- :to="{ name: 'visitors', query: { keyword: $route.query.keyword } }" -->
      <router-link
        v-if="$route.query.keyword != null"
        :to="{ name: 'visitors', query: { keyword: $route.query.keyword } }"
        v-slot="{ href, route, navigate }"
        custom
      >
        <a :href="href" @click="navigate" class="mr-2"
          ><i class="fa fa-chevron-left mr-1"></i>Back</a
        >
      </router-link>
      <router-link
        v-else
        :to="{ name: 'visitors' }"
        v-slot="{ href, route, navigate }"
        custom
      >
        <a :href="href" @click="navigate" class="mr-2"
          ><i class="fa fa-chevron-left mr-1"></i>Back</a
        >
      </router-link>
    </div>
    <h6 class="d-flex pb-2"><strong>Visit History</strong></h6>
    <div class="d-flex justify-content-between">
      <b-table
        :busy="busy"
        :items="visitsList"
        :fields="visitTableFields"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="searchTerms"
        class="tableBorder"
        stacked="md"
        responsive
        small
        show-empty
        striped
      >
        <template #table-busy>
          <div class="text-center text-white my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(index)="row">
          {{ row.index + 1 }}
        </template>
        <template #cell(visit_code)="row">
          <router-link
            :to="{
              name: 'visit-detail',
              params: {
                id: row.item.id,
                visit_code: row.item.visit_code,
                status: row.item.status
              },
              query: { parents_url: 'visitors' }
            }"
            v-slot="{ href, route, navigate }"
            custom
          >
            <a :href="href" @click="navigate" class="mr-2">
              <u> {{ row.item.visit_code }}</u></a
            >
          </router-link>
        </template>
        <template #cell(visitor_type)="row">
          <div>
            {{ row.item.visitor_type | humanizeText | dashForEmptyText }}
          </div>
        </template>

        <template #cell(host)="row">
          <i class="far fa-building mr-1"></i
          ><strong class="company-font">{{
            row.item.company.name | humanizeText
          }}</strong>
          <div v-for="host in row.item.host" :key="'_' + host.id">
            <i class="fas fa-user-alt mr-1"></i
            ><strong class="host-font">{{ host.name | humanizeText }}</strong>
          </div>
        </template>

        <template #cell(visit_start_time)="row">
          <div v-if="row.item.visit_start_time != null">
            {{ row.item.visit_start_time | unixToTime12Hours }}
          </div>
          <div v-if="row.item.visit_start_time == null">
            {{ row.item.visit_start_time | dashForEmptyText }}
          </div>
        </template>

        <template #cell(visit_end_time)="row">
          <div v-if="row.item.visit_end_time != null">
            {{ row.item.visit_end_time | unixToTime12Hours }}
          </div>
          <div v-if="row.item.visit_end_time == null">
            {{ row.item.visit_end_time | dashForEmptyText }}
          </div>
        </template>

        <template #cell(additional_metadata.check_in_time)="row">
          <div v-if="row.item.additional_metadata.check_in_time != undefined">
            {{ row.item.additional_metadata.check_in_time | unixToTime12Hour }}
          </div>
          <div v-if="row.item.additional_metadata.check_in_time == undefined">
            {{ row.item.additional_metadata.check_in_time | dashForEmptyText }}
          </div>
        </template>

        <template #cell(additional_metadata.check_out_time)="row">
          <div v-if="row.item.additional_metadata.check_out_time != undefined">
            {{ row.item.additional_metadata.check_out_time | unixToTime12Hour }}
          </div>
          <div v-if="row.item.additional_metadata.check_out_time == undefined">
            {{ row.item.additional_metadata.check_out_time | dashForEmptyText }}
          </div>
        </template>

        <template #cell(visit_purpose)="row">
          <div v-if="row.item.visit_purpose == null">
            {{ row.item.visit_purpose | dashForEmptyText }}
          </div>
          <div v-if="row.item.visit_purpose != null">
            {{ row.item.visit_purpose | humanizeText }}
          </div>
        </template>

        <template #cell(actions)="row">
          <b-button-group size="sm">
            <!-- declaration form -->
            <b-button
              variant="secondary"
              size="sm"
              v-b-modal.declaration-form
              v-b-tooltip.hover
              title="Declaration Form"
              @click="
                showDeclarationForm(row.item.additional_metadata, row.index)
              "
              :disabled="!isFormExist"
            >
              <i class="fas fa-file-alt"></i>
            </b-button>
          </b-button-group>
        </template>
      </b-table>
    </div>
    <!--pagination -->
    <div class="d-flex justify-content-between" v-if="!busy && !search">
      <div class="d-flex">
        <b-form-select
          size="sm"
          id="per-page-select"
          v-model="perPage"
          :options="pageOptions"
          @change="handlePerPageChange"
        ></b-form-select>
        <div class="d-flex w-100">Per Page</div>
      </div>

      <b-pagination
        v-if="perPage !== 'all'"
        size="sm"
        class="d-flex"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        @change="handlePageChange"
      ></b-pagination>
    </div>
    <!-- declaration form-->
    <b-modal
      title="Declaration Form"
      ref="declaration-form"
      centered
      hide-footer
    >
      <b-card-group v-for="form in formList" :key="form.id">
        <b-card>
          <div class="form-container">
            <div :id="form.id" class="form-preview"></div>
          </div>
        </b-card>
      </b-card-group>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import jQuery from "jquery";

let $ = (jQuery = require("jquery"));
$ = $.extend(require("webpack-jquery-ui"));
$ = $.extend(require("webpack-jquery-ui/css"));
$ = $.extend(require("formBuilder/dist/form-render.min.js"));

export default {
  data: function () {
    return {
      busy: false,
      visitsList: [],
      visitTableFields: [
        {
          key: "index",
          label: "No.",
          sortDirection: "desc"
        },
        {
          key: "visit_code",
          label: "Visitation Code",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "visitor_type",
          label: "Visitor Type",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "host",
          label: "Host Name",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "visit_start_time",
          label: "Visit Start Time",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "visit_end_time",
          label: "Visit End Time",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "additional_metadata.check_in_time",
          label: "Check In Time",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "additional_metadata.check_out_time",
          label: "Expiry Time",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "visit_purpose",
          label: "Visit Purpose",
          sortable: true,
          sortDirection: "desc"
        },
        { key: "actions", label: "Actions" }
      ],
      sortDesc: false,
      sortBy: "id",
      sortDirection: "desc",
      searchTerms: "",

      filter: null,
      filterOn: [],

      formId: null,
      formList: [],

      totalRows: 0,
      perPage: 15,
      currentPage: 1,
      pageOptions: [5, 10, 15],
      search: false,
      isFormExist: false
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL"
    }),
    getVisits() {
      return this.$store.getters["visits/getVisits"](null);
    }
  },
  watch: {
    getVisits: function (n, o) {
      this.visitsList = n;
      console.log("this.visitsList ", this.visitsList);
    }
  },
  mounted() {
    this.$route.query;
    console.log("$route.query", this.$route.query);
    this.getVisitList();
  },
  methods: {
    getVisitList() {
      this.busy = true;
      this.visitsList = [];
      this.$store.dispatch("visits/resetVisit");

      let $this = this;
      let API_URL =
        $this.getAPIServerURL +
        "/api/visitorservice/visitor/" +
        this.$route.params.id +
        "/visits/";
      const client = $this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then(async (data) => {
          let validUrl = "";
          let result = data.result;
          if (Object.keys(result).length !== 0) {
            if (result != undefined) {
              outer_loop: for (let key in result) {
                if (result[key] != undefined && Array.isArray(result[key])) {
                  validUrl = key;
                  break outer_loop;
                }
              }
            }

            if (
              result[validUrl] != undefined &&
              Array.isArray(result[validUrl])
            ) {
              for (let key2 in result[validUrl]) {
                result[validUrl][key2]["host"] = null;
                result[validUrl][key2]["company"] = null;

                result[validUrl][key2]["host"] = await this.fetchHosts(
                  result[validUrl][key2].id
                );

                for (let key4 in result[validUrl][key2]["host"]) {
                  result[validUrl][key2]["company"] = await this.fetchCompany(
                    result[validUrl][key2]["host"][key4].company_id
                  );
                }

                if (
                  result[validUrl][key2].additional_metadata.visitor !=
                    undefined &&
                  Object.keys(
                    result[validUrl][key2].additional_metadata.visitor[
                      $this.$route.params.id
                    ].declaration_form_data
                  ).length !== 0
                ) {
                  this.isFormExist = true;
                } else {
                  this.isFormExist = false;
                }
                this.$store.dispatch("visits/addVisit", result[validUrl][key2]);
              }
            }

            $this.busy = false;
          } else {
            $this.busy = false;
          }
        })
        .catch((err) => {
          $this.busy = false;
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },
    async fetchHosts(id) {
      let API_URL =
        this.getAPIServerURL + "/api/visitorservice/visit/" + id + "/hosts/";

      const client = this.$root.getAjaxFetchClient();

      let res = await client.getRequest(API_URL);

      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        outer_loop: for (var key in result) {
          if (result[key] !== undefined) {
            validUrl = key;
            break outer_loop;
          }
        }
      }

      if (result[validUrl] != undefined && Array.isArray(result[validUrl])) {
        return result[validUrl];
      }
      return {};
    },
    async fetchCompany(id) {
      let API_URL =
        this.getAPIServerURL + "/api/visitorservice/company/?company_id=" + id;

      const client = this.$root.getAjaxFetchClient();

      let res = await client.getRequest(API_URL);

      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        outer_loop: for (var key in result) {
          if (result[key] !== undefined) {
            validUrl = key;
            break outer_loop;
          }
        }
      }

      if (result[validUrl] != undefined) {
        return result[validUrl];
      }
      return {};
    },
    setFormData(formList) {
      let $this = this;
      $this.$refs["declaration-form"].show();
      $this.$nextTick(() => {
        $("#" + formList.id).formRender({
          dataType: "json",
          formData: formList.form_data
        });
      });
    },
    showDeclarationForm(data) {
      let $this = this;
      let arrayForm = null;
      let stringArrayForm = null;
      this.formList = [];
      for (let formKey in data.visitor[$this.$route.params.id]
        .declaration_form_data) {
        $this.formId = formKey;
        arrayForm =
          data.visitor[$this.$route.params.id].declaration_form_data[
            $this.formId
          ];
        stringArrayForm = JSON.stringify(arrayForm);
        $this.formList.push({ id: $this.formId, form_data: stringArrayForm });
      }
      for (let key in $this.formList) {
        this.setFormData($this.formList[key]);
      }
    },

    handlePageChange(page) {
      console.log("updated page", page);
      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          page: parseInt(page)
        }
      });
    },
    handlePerPageChange(pageSize) {
      console.log("updated pageSize", pageSize);

      this.$router.push({
        ...this.$route,
        query: {
          ...this.$route.query,
          per_page: parseInt(pageSize)
        }
      });
    }
  }
};
</script>
<style lang="scss">
.form-preview {
  overflow: auto;
  height: auto;

  .rendered-form {
    pointer-events: none;
  }
}

.form-editor-container {
  margin: 20px;
}

.form-container {
  background-color: rgb(255, 255, 255);
  width: calc(100% - 0rem);
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;

  label {
    color: rgb(0, 0, 0);
  }

  span {
    color: rgb(0, 0, 0);
  }

  h1 {
    color: rgb(0, 0, 0);
  }

  h2 {
    color: rgb(0, 0, 0);
  }

  h3 {
    color: rgb(0, 0, 0);
  }

  h4 {
    color: rgb(0, 0, 0);
  }

  h5 {
    color: rgb(0, 0, 0);
  }

  p {
    color: rgb(0, 0, 0);
  }

  a {
    color: rgb(0, 0, 0);
  }

  .title {
    color: rgb(0, 0, 0);
  }

  .modal-content {
    background-color: rgb(255, 255, 255);
  }

  .card {
    background-color: rgb(255, 255, 255);
  }

  .form-header {
    text-align: center;
    size: large;
  }

  .tools {
    color: rgb(195, 192, 192);
  }

  button {
    background-color: rgb(247, 161, 85);
    color: rgb(255, 255, 255);
    margin-top: 10px;
    margin-right: 5px;
    width: 100px;
  }
}

.form-builder-dialog {
  color: rgb(0, 0, 0);
}

ul {
  color: rgb(0, 0, 0);
}
.company-font {
  font-size: var(--font-size-medium);
  //font-size: medium;
}

.host-font {
  font-size: var(--font-size-normal);
}
</style>
